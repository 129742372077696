import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import resourcesHeaderImg from "../images/RESOURCES.svg";
import { Link } from "gatsby";
import HeaderLogo from "../images/pipp-header-logo.svg";
import jacketPdf from "../jkt_pttn.pdf"
import juliaPdf from "../Julia John - Patternmaking from the Body.pdf"

const ListLink = (props) => (
  <li className="header-list-element">
    <Link to={props.to} className="header-list-link">
      {props.children}
    </Link>
  </li>
);

const images = require.context("../images/resources", false, /\.jpg$/);

const getImagesArray = (images) => {
  // images function has a .keys() function which returns a webpack related filepath
  const imagePaths = images.keys();
  // need to call the webpack function to get compiled filepath - see above
  return imagePaths.map((path, index) => {
    let className = "inline-image"
    if (index === 0) {
      className += " single first"
    } else if (index === 8) {
      className += " single last"
    }
    return <img className={className} src={images(path)} alt="" />;
  });
};

export default () => (
  <Layout>
    <SEO title="Resources" />
    <header className="nav-header">
      <nav>
        <Link className="logo-link" to="/">
          <img src={HeaderLogo} alt="Home" />
        </Link>
        <div className="heading-container">
          <ul className="header-ul">
            <ListLink to="/about">about</ListLink>
            <ListLink to="/manifesto">manifesto</ListLink>
            <ListLink to="/upcoming">upcoming</ListLink>
            <ListLink to="/past">past</ListLink>
            <ListLink to="/resources">resources</ListLink>
            <ListLink to="/contact">contact</ListLink>
          </ul>
          <img
            className="heading-img"
            src={resourcesHeaderImg}
            alt="Resources"
          />
        </div>
      </nav>
    </header>
    <main className="resources-main">
      <header>
        <h3>Julia John - Instructional Handout</h3>
      </header>
      <p style={{ textAlign: "center", marginBottom: "4rem" }}>
        Download Julia John's instructional handout, referenced in her
        "Patternmaking from the Body" demo, by clicking{" "}
        <a className="cta" href={juliaPdf}>
          here
        </a>
        .
      </p>
      <header>
        <h3>Amy Sperber - Jacket Pattern</h3>
      </header>
      <p style={{ textAlign: "center", marginBottom: "4rem" }}>
        Download Amy Sperber's jacket pattern, referenced in her "Simple Zero
        Waste Top" demo, by clicking{" "}
        <a className="cta" href={jacketPdf}>
          here
        </a>
        .
      </p>
      <header>
        <h3>Patternmaking at the Kitchen Table</h3>
      </header>
      <p style={{ textAlign: "center" }}>
        <i>Patternmaking at the Kitchen Table</i> is a publication meant to make
        patternmaking accessible to all.
      </p>
      <p style={{ textAlign: "center" }}>
        Distributed at all in-person PIPP events, this booklet aims to help you
        learn the basics of patternmaking at home.
      </p>
      {getImagesArray(images)}
    </main>
  </Layout>
);
